import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useRef, useState } from "react";
import { useSignin } from "./api/authmutations";
import { Dots } from "../../lib/dots";
import TFASettings from "./Tfa";
import EyeSlashIcon from '../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../assets/images/Auth/eye.png'
import { BounceInDownButton } from "../../Components/animation/bouncein";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { WaitingLoader } from "../../Components/Loader/buttonLoad";
import ReCAPTCHA from 'react-google-recaptcha';
import { generateSecureRandomNumber } from "../../hook/generateSecureRandomNumber";

const schema = yup.object().shape({
   user: yup.string()
      .max(140, 'Email / Member ID must be at most 140 characters')
      .required('Email / Member ID is Required').trim(),
   password: yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(30, 'Password must be at most 30 characters')
      .required('Password is Required'),
   octcaptcha: yup.string() 
   .required('Verify you are a Human')
})


const Login = () => {

   const recaptchaRef = useRef();
   const [show, setShow] = useState(true);
   const [tfaStatus, setTfaStatus] = useState(false);
   const navigate = useNavigate();

   const [rememberMe, setRememberMe] = useState(!!Cookies.get('isLoggedIn'));
   const username = Cookies.get('username') ? CryptoJS.AES.decrypt(Cookies.get('username'), process.env.REACT_APP_REMEMBER_KEY).toString(CryptoJS.enc.Utf8) : '';
   const password = Cookies.get('password') ? CryptoJS.AES.decrypt(Cookies.get('password'), process.env.REACT_APP_REMEMBER_KEY).toString(CryptoJS.enc.Utf8) : '';

   const [loading, setLoading] = useState(false)
   const signinMutation = useSignin();
   const { control, handleSubmit, formState: { errors, isValid }, reset, getValues, setValue } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
         user: rememberMe ? username : '',
         password: rememberMe ? password : '',
         octcaptcha: ""
      }
   });

   useEffect(() => {
      if (rememberMe && username && password) {
         setValue('user', username);
         setValue('password', password);
      }
   }, [rememberMe, username, password, setValue]);

   const onSubmit = async (data) => {

      setLoading(true)
      const encryptedUsername = CryptoJS.AES.encrypt(data.user, process.env.REACT_APP_REMEMBER_KEY).toString();
      const encryptedPassword = CryptoJS.AES.encrypt(data.password, process.env.REACT_APP_REMEMBER_KEY).toString();


      if (rememberMe) {
         Cookies.set('isLoggedIn', true, { expires: 7 });
         Cookies.set('username', encryptedUsername, { expires: 7 });
         Cookies.set('password', encryptedPassword, { expires: 7 });
      } else {
         Cookies.remove('isLoggedIn');
         Cookies.remove('username');
         Cookies.remove('password');
      }


      let login_no = Math.floor((generateSecureRandomNumber() * 10000) + 1);
      data["login_no"] = login_no
      data["octcaptcha"] = login_no
      let res = await signinMutation.mutateAsync(data)
      if (res?.status && res?.tfa_status === false) {
         setLoading(false)
         sessionStorage.setItem("loginno", data.login_no)
         sessionStorage.setItem("email", data?.user)
         sessionStorage.setItem("token_ana", res.token)
         sessionStorage.setItem('type', 'user')
         reset();
         navigate('/dashboard')
      } else if (res?.status && res?.tfa_status) {
         setLoading(false)
         setTfaStatus(true)
      }
      else {
         setLoading(false)
      }
      // if (res.status) {
      //    setLoading(false)
      //    setTfaStatus(true)
      // }
      // else {
      //    setLoading(false)
      // }
   }

   const handleRememberMe = (e) => {
      setRememberMe(e.target.checked);
   };

   const showPassword = e => {
      if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
         setShow(!show)
      }
   }

   return (
      tfaStatus ?
         <TFASettings email={getValues('user')} />
         :
         <BounceInDownButton>
            {loading && <WaitingLoader />}
            <div className="row justify-content-center animated_div">
               <div className="col-lg-6 col-xl-4">
                  <h3 className="modalHeading">Welcome Back</h3>
                  <p className="modalSubHeading">Login to manage your account</p>
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                     <div className="form-group">
                        <label className="formLabel" htmlFor="loginId1">Email ID / Member ID</label>
                        <Controller name='user' control={control} render={({ field }) => (
                           <input
                              {...field}
                              type="text"
                              autoFocus='on'
                              className="form-control"
                              placeholder='Enter your email / member ID'
                              autoComplete='off'
                           />
                        )} />

                        {errors.user && <p className="errMsg">* {errors.user.message}</p>}
                     </div>

                     <div className="form-group">
                        <label className="formLabel" htmlFor="loginId2">Password</label>
                        <div className="input-group mb-3">
                           <Controller name='password' control={control} render={({ field }) => (
                              <input
                                 {...field}
                                 type={show ? "password" : "text"}
                                 id="loginId2"
                                 className="form-control"
                                 placeholder='Enter your password'
                                 autoComplete='off'
                              />
                           )} />
                           <div className="input-group-append">
                              <span className="input-group-text" type="button" onClick={(e) => showPassword(e)} onKeyDown={(e) => showPassword(e)}>
                                 {show ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                    <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                              </span>
                           </div>
                        </div>
                        {errors.password && <p className="errMsg">* {errors.password.message}</p>}
                     </div>
                     <div className="d-flex">
                        <Link to='/forget-password' className="yellowTxt ml-auto">Forgot Password</Link>
                     </div>
                     <div className="d-flex">
                        <Link to='/forget-mpin' className="yellowTxt ml-auto">Forgot Mpin</Link>
                     </div>
                     <div className="d-flex">
                        <div>
                           <label className="checkCnt" htmlFor="loginCheckBox">Remember Me
                              <input type="checkbox"
                                 id="loginCheckBox"
                                 name="rememberMe"
                                 checked={rememberMe}
                                 onChange={handleRememberMe}
                                 required
                              />
                              <span className="checkmark"></span>
                           </label>
                        </div>
                     </div>
                     <div className="my-4">
                        <div className='d-flex justify-content-center align-items-center'>
                           <div className='d-flex justify-content-center my-4'>
                              <Controller name='octcaptcha' control={control} render={({ field }) => (
                                 <ReCAPTCHA
                                    {...field}
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}

                                 />
                              )} />
                           </div>

                        </div>
                        {errors?.octcaptcha && <p className="errMsg text-center">{errors.octcaptcha.message}</p>}
                     </div>

                     <div className="d-flex justify-content-center my-4">
                        <button className="btn btn-11545 btnGold" type="submit" disabled={signinMutation.isLoading || !isValid}>
                           {signinMutation.isLoading ?
                              <Dots>Loggin-in</Dots>
                              :
                              "Login"
                           }
                        </button>
                     </div>
                     <p className="text-center">Don’t have an account?
                        <Link to='/register' className="yellowTxt mx-2">Sign up Here.</Link>
                     </p>
                  </form>
               </div>
            </div>
         </BounceInDownButton>
   )
}
export default Login;