import { Fragment, useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ButtonLoader } from "../../../Components/Loader/buttonLoad";
import { Dots } from "../../../lib/dots";
import { Timer } from "../../../lib/expireTime";
import { CheckCircle } from "lucide-react";
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from "../../../hook/generateSecureRandomNumber";
import { useSendOTP } from "../api/depositewithdrawapi";
import { useToast } from "../../../hook/usetoast";


const schema = yup.object().shape({
    otp: yup.string(),
});

const VerifyEmail = ({ iswithdrawverified, setIswithdrawverified, withdrawsuccess }) => {
    console.log("✨✨✨✨ ~ VerifyEmail ~ withdrawsuccess:", withdrawsuccess)
    const [mailComplete, setMailComplete] = useState(false);
    const [mailOTP, setMailOTP] = useState("");
    const [waitingTime, setWaitingTime] = useState(false);
    const [otpSend, setSendotp] = useState(false);

    const sendMutation = useSendOTP();

    const { control, handleSubmit, formState: { errors }, getValues, clearErrors, setError, watch, reset, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            otp: ""
        }
    });

    useEffect(() => {
        if (withdrawsuccess) {
            setWaitingTime(false);
            setSendotp(false)
            setIswithdrawverified(false)
            setMailComplete(false);
            setValue("otp", '')
            setMailOTP('');
            clearErrors("otp");
            reset()
        } else {
            let interval = setTimeout(() => {
                setWaitingTime(false);
            }, 60000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [waitingTime, withdrawsuccess])

    const watchedEmail = watch("octEmail"); // Watch for changes in the email field

    const toast = useToast()
    useEffect(() => {
        if (mailComplete && watchedEmail !== getValues("octEmail")) {
            // Resetting OTP when email changes after sending it
            setMailComplete(false);
            setMailOTP('');
            clearErrors("otp");
        }
    }, [watchedEmail, getValues, mailComplete, clearErrors]);

    const handleKeyDown = (e) => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0];

    const sendMailOTP = async () => {
        let random = Math.floor(generateSecureRandomNumber() * 10000);
        sessionStorage.setItem('random', random);
        const send = await sendMutation.mutateAsync({ status: "sendotp" })
        if (send.status) {
            setSendotp(true)
            setWaitingTime(true);
            setMailComplete(true);
            toast.success(send.message)
        }
        else {
            setSendotp(false)
            setWaitingTime(false);
            setMailComplete(false);
            toast.error(send.message)
            reset()
        }

    };

    const verifyMailOTP = async () => {
        clearErrors("otp");
        if (!mailOTP) {
            return setError("otp", { message: "OTP is required" });
        } else if (mailOTP.length !== 6) {

            return setError("otp", { message: "Invalid OTP" });
        }
        const send = await sendMutation.mutateAsync({ status: "verifyotp", code: mailOTP })
        if (send.status) {
            setSendotp(false)
            setIswithdrawverified(true)
            toast.success(send.message)
        }
        else {
            setWaitingTime(false);
            setSendotp(false)
            setIswithdrawverified(false)
            toast.error(send.message)
            reset()
        }
    };

    return (
        <Fragment>
            <div className="col-lg-6">
                <div className="form-group">
                    <label className="formLabel" htmlFor="regId4">OTP sent to Email ID</label>
                    {!iswithdrawverified ?
                        <div className="input-group mb-3">
                            <input
                                type="tel"
                                className="form-control"
                                maxLength={6}
                                value={mailOTP}
                                disabled={!mailComplete}
                                onChange={(e) => setMailOTP(e.target.value)}
                                onInput={handleKeyDown}
                                placeholder="Enter your OTP"
                            />
                            <div className="input-group-append">
                                {!withdrawsuccess ? <>
                                    {
                                        waitingTime ?

                                            <span className="input-group-text yellowTxt">
                                                < Timer />
                                            </span>
                                            :

                                            <span className="input-group-text yellowTxt" type="button" onClick={sendMailOTP} onKeyDown={sendMailOTP}>
                                                {mailComplete ? "Re-send" : "Send OTP"}

                                            </span>

                                    }
                                </> : " "}

                            </div>
                            {otpSend ? <div className="input-group-append">
                                <span className="input-group-text yellowTxt" type="button" onClick={handleSubmit(verifyMailOTP)} onKeyDown={handleSubmit(verifyMailOTP)}>
                                    Verify
                                </span>

                            </div> : " "}

                        </div>
                        :
                        <div className="input-group mb-3">
                            <input
                                type="tel"
                                className="form-control"
                                maxLength={6}
                                value={mailOTP}
                                disabled
                                placeholder="Enter your OTP"
                            />
                        </div>

                    }
                    {errors.otp && <p className="errMsg">* {errors.otp.message}</p>}
                </div>
            </div>
        </Fragment >
    );
};

VerifyEmail.propTypes = {
    setValue: PropTypes.func.isRequired,
};

export default VerifyEmail;