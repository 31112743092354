import { Parallax } from "react-scroll-parallax";
import "../../../../assets/scss/Home/HomeStaking/HomeStaking.scss";
import { UseGetIcoStaking } from '../../api/fetchHomecon'
import PropTypes from 'prop-types';
// import gitness from '../../../../assets/images/gitness-spline-testing.mp4'
const HomeStaking = ({ data }) => {
   const { data: icostaking, isLoading } = UseGetIcoStaking()
   let totalAlloction = icostaking?.status ? icostaking?.data?.find(item => item.status == "active") : ""


   return (
      <div className="hmStk" id="stakingSec">
         <div className="hmStkImgs">
            <div className="hmStkImgsCnt">
               <div className="hmStkImg1">
                  <video autoPlay={true} muted playsInline loop>
                     <source src="https://octaverselive.s3.amazonaws.com/gitness-spline-testing.mp4" type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
               </div>
               <div className="hmStkImg2">
                  <Parallax speed={5}>
                     <img src={require("./assets/basc-coin.gif")} alt="basc-coin" className="img-fluid" />
                  </Parallax>
                  <div className="hmStkImg2Rv">
                     <img src={require("./assets/basc-coin.gif")} alt="basc-coin" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
               <div className="col-lg-5">
                  <div className="hmStkHd">
                     <Parallax speed={3}>
                        <h2 className="secTl">
                           <span className="txt-gradient">{data?.status ? data?.data[15]?.title : " "}</span>
                        </h2>
                     </Parallax>
                     <p className="secPara" dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[15]?.pagecontent : '' }} />
                  </div>

                  <div className="col-md-8">
                     <div className="hmStkAlcBx">
                        <div className="hmStkAlcBxTp">
                           <div className="row align-items-center">
                              <div className="col-sm-6">
                                 <div className="hmStkNbTxG">
                                    <div className="hmStkNb">
                                       <span className="">{!isLoading ? icostaking?.status ? "0" + icostaking?.data[0]?.phase?.split(' ')[1] : " " : ""}</span>
                                    </div>
                                    <div className="hmStkNbTx">
                                       PHASE
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="hmStkAlcLbAm">
                                    <div className="hmStkAlcLb">
                                       ALLOCATION
                                    </div>
                                    <div className="hmStkAlcAm">
                                       <span>
                                          <img src={require("./assets/coin.png")} alt="coin" className="img-fluid" />
                                       </span>
                                       <span>{!isLoading ? icostaking?.status ? (totalAlloction?.totalAllocation) : " " : ""}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="hmStkAlcBxBm">
                           <div className="row">
                              {icostaking?.data?.length > 0 ?
                                 icostaking?.data?.map((ico) => {

                                    if (totalAlloction?._id == ico._id) {
                                       return (
                                          <div className="col-6 mb-3" key={ico._id}>
                                             <div className="hmStkLk">
                                                <div className="hmStkLkTx">
                                                   <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M3.33301 9.66683C3.33301 9.31321 3.47348 8.97407 3.72353 8.72402C3.97358 8.47397 4.31272 8.3335 4.66634 8.3335H11.333C11.6866 8.3335 12.0258 8.47397 12.2758 8.72402C12.5259 8.97407 12.6663 9.31321 12.6663 9.66683V13.6668C12.6663 14.0205 12.5259 14.3596 12.2758 14.6096C12.0258 14.8597 11.6866 15.0002 11.333 15.0002H4.66634C4.31272 15.0002 3.97358 14.8597 3.72353 14.6096C3.47348 14.3596 3.33301 14.0205 3.33301 13.6668V9.66683Z" stroke="#00C537" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M7.33301 11.6667C7.33301 11.8435 7.40325 12.013 7.52827 12.1381C7.65329 12.2631 7.82286 12.3333 7.99967 12.3333C8.17649 12.3333 8.34605 12.2631 8.47108 12.1381C8.5961 12.013 8.66634 11.8435 8.66634 11.6667C8.66634 11.4899 8.5961 11.3203 8.47108 11.1953C8.34605 11.0702 8.17649 11 7.99967 11C7.82286 11 7.65329 11.0702 7.52827 11.1953C7.40325 11.3203 7.33301 11.4899 7.33301 11.6667Z" stroke="#00C537" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M9.33301 6.33333V3.66667C9.33301 2.95942 9.61396 2.28115 10.1141 1.78105C10.6142 1.28095 11.2924 1 11.9997 1C12.7069 1 13.3852 1.28095 13.8853 1.78105C14.3854 2.28115 14.6663 2.95942 14.6663 3.66667V6.33333" stroke="#00C537" strokeLinecap="round" strokeLinejoin="round" />
                                                   </svg>
                                                   <span>Locker 0{ico.lockerCount}</span>
                                                </div>
                                                <div className="hmStkLkTx">
                                                   {/* <span>{!isLoading ? ico?.allocation : ""} ANA</span> */}
                                                </div>
                                                <div className="hmStkLkTx">
                                                   <span>{!isLoading ? ico?.startDay : ""}-{!isLoading ? ico?.endDay : ""} Days</span>
                                                </div>
                                             </div>
                                          </div>
                                       )

                                    } else {
                                       return (
                                          <div className="col-6 mb-3" key={ico._id}>
                                             <div className="hmStkLk">
                                                <div className="hmStkLkTx v2">
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M3.33301 8.66683C3.33301 8.31321 3.47348 7.97407 3.72353 7.72402C3.97358 7.47397 4.31272 7.3335 4.66634 7.3335H11.333C11.6866 7.3335 12.0258 7.47397 12.2758 7.72402C12.5259 7.97407 12.6663 8.31321 12.6663 8.66683V12.6668C12.6663 13.0205 12.5259 13.3596 12.2758 13.6096C12.0258 13.8597 11.6866 14.0002 11.333 14.0002H4.66634C4.31272 14.0002 3.97358 13.8597 3.72353 13.6096C3.47348 13.3596 3.33301 13.0205 3.33301 12.6668V8.66683Z" stroke="#FF4949" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M7.33301 10.6667C7.33301 10.8435 7.40325 11.013 7.52827 11.1381C7.65329 11.2631 7.82286 11.3333 7.99967 11.3333C8.17649 11.3333 8.34605 11.2631 8.47108 11.1381C8.5961 11.013 8.66634 10.8435 8.66634 10.6667C8.66634 10.4899 8.5961 10.3203 8.47108 10.1953C8.34605 10.0702 8.17649 10 7.99967 10C7.82286 10 7.65329 10.0702 7.52827 10.1953C7.40325 10.3203 7.33301 10.4899 7.33301 10.6667Z" stroke="#FF4949" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M5.33301 7.33333V4.66667C5.33301 3.95942 5.61396 3.28115 6.11406 2.78105C6.61415 2.28095 7.29243 2 7.99967 2C8.70692 2 9.3852 2.28095 9.88529 2.78105C10.3854 3.28115 10.6663 3.95942 10.6663 4.66667V7.33333" stroke="#FF4949" strokeLinecap="round" strokeLinejoin="round" />
                                                   </svg>
                                                   <span>Locker 0{ico.lockerCount}</span>
                                                </div>
                                                <div className="hmStkLkTx v2">
                                                   {/* <span>{!isLoading ? ico?.allocation : ""} ANA</span> */}
                                                </div>
                                                <div className="hmStkLkTx v2">
                                                   <span>{!isLoading ? ico?.startDay : ""}-{!isLoading ? ico?.endDay : ""} Days</span>
                                                </div>
                                             </div>
                                          </div>
                                       )
                                    }
                                 })
                                 :
                                 null
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-7">

                  <div className="hmStkDts">
                     <div className="row">
                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/signup.png")} alt="signup" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[8]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[8]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>

                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/deposit.png")} alt="signup" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[9]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[9]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>

                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/swap.png")} alt="swap" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[10]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[10]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>

                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/stake.png")} alt="stake" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[7]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[7]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>
                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/reward.png")} alt="reward" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[11]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[11]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>

                        <div className="col-md-6">
                           <Parallax speed={3}>
                              <div className="hmStkDtsCd">
                                 <div className="hmStkDtsIc">
                                    <img src={require("./assets/redeem.png")} alt="redeem" className="img-fluid" />
                                 </div>
                                 <div className="hmStkDtsTx">
                                    <h5>{data?.status ? data?.data[12]?.title : ''}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: data?.status ? data?.data[12]?.pagecontent : '' }} />
                                 </div>
                              </div>
                           </Parallax>
                        </div>

                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   )
}
HomeStaking.propTypes ={
   data: PropTypes.shape({
         status: PropTypes.any,
         data: PropTypes.arrayOf(
            PropTypes.shape({
               title: PropTypes.any,
               pagecontent : PropTypes.any
            })
        ),
    }), 
}
export default HomeStaking;