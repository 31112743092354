import React, { useEffect, useState } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import EyeSlashIcon from '../../../assets/images/Auth/eye-slash.png'
import EyeIcon from '../../../assets/images/Auth/eye.png'
import { useWithdraw } from '../api/depositewithdrawapi';
import { useModalContext } from '../../../contexts/ModalContext';
import { Submitbutton } from '../../common/Submitbutton';
import { queryClient } from '../../../config';
import { useToast } from '../../../hook/usetoast';
import { networkSymbols } from '../../../lib/network';
import { WaitingLoader } from '../../../Components/Loader/buttonLoad';
import MultiCoinValidator from 'multicoin-address-validator';
import { formatAmount } from '../../../lib/formatAmount';
import { useDebounce } from '../../../hook/useDounce';
import PropTypes from 'prop-types';
import { generateSecureRandomNumber } from '../../../hook/generateSecureRandomNumber';
import WithdrawVerification from './withdrawVerify';

function Withdraw({ userCoins }) {

    const withdrawmutation = useWithdraw();
    const { siteSettings, setMinWithdraw } = useModalContext();
    const [randomdata, setRandomdata] = useState(Math.floor(100000 + generateSecureRandomNumber() * 900000))
    const [loading, setLoading] = useState(false)
    const [mpshow, setMpshow] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState("")
    const [amount, setAmount] = useState("0.00");
    const [feeAmount, setFeeAmount] = useState(0);
    const [resfee, setResfee] = useState(0)
    const [selectedBalance, setSelectedBalance] = useState("")

    const amountVal = useDebounce(feeAmount)

    useEffect(() => {
        getFee();
    }, [amountVal])

    const getFee = async () => {

        setResfee(selectedCurrency.fee)
        let value = Number(selectedCurrency.fee) / 100;
        value = value * Number(amountVal)
        value = Number(amountVal) - value
        setAmount(value)
    }


    const schema = yup.object().shape({
        currency: yup.string().required('Select Currency'),
        MPIN: yup.string(),
        // .required('MPIN is Required')
        // .min(6, 'MPIN must be 6 digits'),
        address: yup.string()
            .required('Wallet Address is required')
            .test((val, validate) => {
                if (selectedCurrency) {
                    const isValidAddress = MultiCoinValidator.validate(val, selectedCurrency?.currencyNetwork == "ethereum" ? "ETH" : selectedCurrency?.currencyNetwork == "binance" ? "BNB" : selectedCurrency?.currencyNetwork == "polygon" ? "MATIC" : selectedCurrency?.currencyNetwork == "tron" ? "TRX" : "SOL");
                    if (isValidAddress) return val
                    return validate.createError({ message: "Invalid Address" });
                } else {
                    return validate.createError({ message: "Select Currency" });
                }

            }),
        amount: yup.number()
            .typeError("Amount is required")
            .required('Amount is required')
            .test('max-decimal-places', 'The amount must have at most 6 decimal places', value => {
                if (value) {
                    const numString = formatAmount(value.toString());
                    const [, decimal] = numString.includes('.') ? numString.split('.') : [numString, ''];
                    return !decimal || decimal.length >= 6;
                }
                return true;
            })

            .positive('Amount must be a positive number')
            .test('not-zero', 'The amount should be at least Zero above', (value) => value > 0)
            .max(999999, 'Price field must be less than or equal to 999999'),
        // otp: yup.string()
        //     .required('OTP required'),

    })


    const toast = useToast()
    const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, watch, clearErrors } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            currency: "",
            MPIN: "",
            address: "",
            amount: "",
        }
    });
    const watchField = watch("currency")
    const handleKeyDown = e => e.target.value = e.target.value.match(/^[0-9]{0,6}/)[0]

    useEffect(() => {
        if (selectedCurrency) {
            setMinWithdraw(selectedCurrency.minimumWithdraw)

        }
    }, [selectedCurrency])
    
    const [withdrawsuccess, setIswithdrawsuccess] = useState(false);

    const handleCurrencyChange = (selectedId) => {
        setAmount("");
        setMinWithdraw('')
        setValue("amount", '')
        setValue("address", '')
        setValue("MPIN", '')
        clearErrors()

        const selectCurrency = userCoins?.find(c => c._id === selectedId) || {};
        setSelectedCurrency(selectCurrency);
        setSelectedBalance(selectCurrency.balance);

        setValue("currency", selectCurrency.currency)
        setValue("network", selectCurrency.currencyNetwork)
        setIswithdrawsuccess(false)


    };

    const [iswithdrawverified, setIswithdrawverified] = useState(false);
  


    const onSubmit = async (data) => {

        setLoading(true)
        if (iswithdrawverified) {
            const { minimumWithdraw, maximumWithdraw } = selectedCurrency;

            const amount = +data.amount;

            if (minimumWithdraw <= amount && maximumWithdraw >= amount) {

                const obj = {
                    network: data.network,
                    currencyName: data.currency,
                    coinType: data.currency === "USDT" ? "token" : "coin",
                    address: data.address,
                    amount: amount,
                    MPIN: data.MPIN,
                    randomdata: randomdata,
                    fee: resfee
                };

                await withdrawmutation.mutateAsync(obj);
                queryClient.refetchQueries("withdrawHistory");
                queryClient.refetchQueries("user-Transactions")
                reset();
                setAmount("");
                setMinWithdraw('')
                setSelectedCurrency('')
                setSelectedBalance('')
                setRandomdata(Math.floor(100000 + generateSecureRandomNumber() * 900000))
                setLoading(false)
                setIswithdrawsuccess(true)
                getFee()

            } else {
                const errorMessage = minimumWithdraw > amount
                    ? `Minimum withdraw amount ${minimumWithdraw}`
                    : `Maximum withdraw amount ${maximumWithdraw}`;

                toast.error(errorMessage);
                reset();
                setAmount("");
                setMinWithdraw('')
                setSelectedCurrency('')
                setSelectedBalance('')
                setIswithdrawsuccess(true)
                setLoading(false)
            }
        }
        else {
            toast.error("Otp is required");
            setLoading(false)
        }
    };

    const showFunc = async (e) => {
        if (e.key === "Enter" || e.nativeEvent.pointerType === "mouse" || e.nativeEvent.pointerType === "touch") {
            setMpshow(!mpshow)
        }
    }

    return (
        <div>
            {loading && <WaitingLoader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId1">Select</label>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    {selectedCurrency ?
                                        < img src={selectedCurrency?.currencyImage} width="20" height="20" alt="" className='mr-2' />
                                        : ""
                                    }
                                    {getValues("currency") || "Select Currency"}
                                    {selectedCurrency?.currency === 'USDT' && networkSymbols[selectedCurrency?.currencyNetwork]}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {userCoins?.filter(c => c.status && c._id != selectedCurrency._id).map((n, index) => (

                                        <DropdownItem tag="a" key={n._id} onClick={() => handleCurrencyChange(n._id)}
                                        >
                                            <span className="mx-2"> < img src={n.currencyImage} width="20" height="20" alt="" className='mr-2' />
                                                {n.currency}  {n?.currency === 'USDT' && networkSymbols[n?.currencyNetwork]} </span>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <span className="prmyTxt ml-sm-auto">
                                {selectedBalance && selectedBalance >= 0 && !isNaN(selectedBalance) ? `Balance : ${parseFloat(selectedBalance.toFixed(6))}` : ''}
                            </span>
                            {!watchField && errors.currency && <p className="errMsg">* {errors.currency.message}</p>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId3">Amount</label>
                            <Controller name='amount' control={control} render={({ field }) => (
                                <input
                                    {...field}
                                    type="number"
                                    onChange={e => {
                                        const value = e.target.value;
                                        const regex = /^\d*(\.\d{0,6})?$/;
                                        if (regex.test(value) || value === "") {
                                            field.onChange(value);
                                            setFeeAmount(value);
                                        }
                                    }}
                                    className={`form-control  ${errors.amount && "is-invalid"}`}
                                    placeholder="Enter your amount"
                                    autoComplete='off'
                                    inputMode="decimal"
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            )} />
                            {errors.amount && <p className="errMsg">* {errors.amount.message}</p>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="formLabel" htmlFor="loginId4">Wallet Address</label>
                            <Controller name='address' control={control} render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your wallet address"
                                    autoComplete='off'
                                />
                            )} />
                            {errors.address && <p className="errMsg">* {errors.address.message}</p>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="formLabel" htmlFor="pwdId3">MPIN</label>
                            <div className="input-group mb-3">
                                <Controller name='MPIN' control={control} render={({ field }) => (
                                    <input
                                        {...field}
                                        type={mpshow ? "password" : "text"}
                                        className={`form-control  ${errors.MPIN && "is-invalid"}`}
                                        onInput={handleKeyDown}
                                        maxLength={6}
                                        id="regId9" placeholder="Enter your MPIN"
                                        autoComplete='off'
                                    />
                                )} />
                                <div className="input-group-append">
                                    <span className="input-group-text" type="button" onClick={(e) => showFunc(e)} onKeyDown={(e) => showFunc(e)}>
                                        {mpshow ? <img src={EyeSlashIcon} width="20" height="20" alt="eyeSlashIcon" /> :
                                            <img src={EyeIcon} width="20" height="20" alt="eyeSlashIcon" />}
                                    </span>
                                </div>
                            </div>
                            {errors.MPIN && <p className="errMsg">* {errors.MPIN.message}</p>}
                        </div>
                    </div>
                    <WithdrawVerification
                        iswithdrawverified={iswithdrawverified}
                        setIswithdrawverified={setIswithdrawverified}
                        withdrawsuccess={withdrawsuccess}
                    />
                    {!setIswithdrawverified && <p className="errMsg">* {errors.otp.message}</p>}
                    <div className="col-12 d-flex justify-content-center">
                        {amount && selectedCurrency ?
                            <div className="d-flex align-items-center justify-content-center w-50">
                                <h3 className="cardLabel mx-3">Fees</h3>
                                <h3 className="cardLabel prmyTxt mx-3">{resfee || 0} %</h3>
                            </div>
                            : ""
                        }
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        {amount && selectedCurrency ?
                            <div className="d-flex align-items-center justify-content-center w-50">
                                <h3 className="cardLabel mx-3">Total Amount</h3>
                                <h3 className="cardLabel prmyTxt mx-3">{amount} {selectedCurrency?.currency} {selectedCurrency?.currency === 'USDT' && networkSymbols[selectedCurrency?.currencyNetwork]}</h3>
                            </div>
                            :
                            ""
                        }
                    </div>
                    <div className="col-12 d-flex justify-content-center mt-3">
                        <Submitbutton
                            buttonText="Submit"
                            loading={withdrawmutation.isLoading}
                            Status={siteSettings?.withdrawStatus}
                            message="Withdrawal disabled. Users cannot be able to Withdrawal temporarily"

                        />

                    </div>
                </div>
            </form>

        </div>
    )
}
Withdraw.propTypes = {
    userCoins: PropTypes.any
}
export default Withdraw