import { Link } from "react-router-dom";
import "../../assets/scss/Innerpages/Innerpages.scss";
import userAV from "./userAV.webp";
import verifiedBadge from "../../assets/images/verifiedBadge.png";
import whatsapp from "../../assets/icons/whatsapp.svg";
import facebook from "../../assets/icons/facebook.svg";
import telegram from "../../assets/icons/telegram.svg";
import { useEffect, useState } from "react";
import { useToast } from "../../hook/usetoast";
import { BadgeCheck, BadgeX, CheckCircle, CloudUpload , Copy } from "lucide-react";
import LoginHistory from "./tabs/loginHistory";
import Aos from "aos";
import TransactionHistory from "./tabs/transactionHistory";
import Converter from "../../lib/converter";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { useModalContext } from "../../contexts/ModalContext";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TwoFactor from "./tabs/TwoFactor";
import { WaitingLoader } from "../../Components/Loader/buttonLoad";

const Dashboard = () => {

   const toast = useToast()
   const [isCopied, setIsCopied] = useState(false);

   const { profileData, isLoading } = useModalContext();

   const [modal, setModal] = useState(false);
   const toggle = () => setModal(false);

   useEffect(() => {
      Aos.init({ once: true });
      window.scrollTo(0, 0)
   }, [])

   const copytoClip = async (id,name) => {
      
      toast.copy("Referral link copied")
      setIsCopied(true)
      if ("clipboard" in navigator) {
         await navigator.clipboard.writeText(`${window.location.origin}/referral/${id}/${name}`);
      } else {
         document.execCommand("copy", true, `${window.location.origin}/referral/${id}/${name}`);
      }
      setTimeout(() => {
         setIsCopied(false);
      }, 1500);
   }



   return (
      <>
         {isLoading && <WaitingLoader />}
         <div className="container animated_div">
            <h3 className="secHeading text-center">Welcome to ANACOIN ICO</h3>
            <div className="row">
               <div className="col-12">
                  <div className="card">
                     <div className="cardInnerCnt d-flex align-items-center flex-wrap">
                        <div className="profileInfoCnt d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center">
                           <img src={profileData?.profileImage || userAV} width="90" height="90" alt="profPic" className="mr-sm-3" />
                           <div className="d-flex flex-column text-center text-sm-left">
                              <h3 className="profileName">{profileData?.name || "---"}</h3>
                              <div className="d-flex align-items-center">
                                 <p className="profileMail mr-3">{profileData?.email || "---"}</p>
                                 <img src={verifiedBadge} width="12" height="12" alt="verifiedBadge" />
                              </div>
                              <Link to="/profile" className="cardLink">View Profile</Link>
                           </div>
                        </div>

                        <div className="idCnt d-flex justify-content-md-center align-items-center">
                           <div>
                              <h5 className="cardLabel mb-0">Member ID</h5>
                              <h3 className="cardValue mb-0">{profileData?.memberID || "---"}</h3>
                           </div>
                        </div>

                        <div className="regDateCnt d-flex justify-content-md-center align-items-center">
                           <div>
                              <h5 className="cardLabel mb-0">Registration Date</h5>
                              <h3 className="cardValue mb-0">{profileData?.registeredDate ? Converter.formatDateandMonth(profileData?.registeredDate) : "--/--/--"}</h3>
                           </div>
                        </div>

                        <div className="dayCnt d-flex justify-content-md-center align-items-center">
                           <div>
                              <h5 className="cardLabel mb-0">Project Day Count</h5>
                              <h3 className="cardValue mb-0">{profileData?.projectDayCount}</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="row">
               <div className="col-md-12 col-xl-4">
                  <div className="card">
                     <div className="cardInnerCnt">
                        <div className="d-flex align-items-center">
                           <div>
                              <h5 className="cardLabel">KYC Verifications</h5>
                              {profileData?.kyc_status === 3 ?
                                 <h3 className="cardValue d-flex align-items-center mb-0 successTxt">
                                    <BadgeCheck size={20} className="mr-2" /> Verified
                                 </h3>
                                 :
                                 profileData?.kyc_status === 1 ?
                                    <h3 className="cardValue d-flex align-items-center mb-0 text-danger">
                                       <BadgeX size={20} className="mr-2" /> Pending
                                    </h3>
                                    :
                                    profileData?.kyc_status === 2 ?
                                       <h3 className="cardValue d-flex align-items-center mb-0 text-danger">
                                          <BadgeCheck size={20} className="mr-2" /> Rejected
                                       </h3>
                                       :
                                       <h3 className="cardValue d-flex align-items-center mb-0 text-danger">
                                          <CloudUpload size={20} className="mr-2" /> Upload KYC
                                       </h3>
                              }
                           </div>
                           <Link to="/kyc" className="cardLink ml-auto">View</Link>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="col-md-6 col-xl-9">
                  <div className="card">
                     <div className="cardInnerCnt">
                     <h5 className="cardLabel mb-0">Referral Link</h5>
                     <p>{`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}</p>
                                 {isCopied ?
                                    <CheckCircle width={20} color="#F8DC8F" className="ml-2" />
                                    :
                                    <Copy size={20} className="cardLink ml-2" style={{ cursor: "pointer" }} onClick={() => copytoClip(profileData?.memberID,profileData?.urlname)} />
                                 }
                        {/* <h5 className="cardLabel">Two factor authentication</h5>
                        <Link onClick={toggle} className="cardLink ml-auto">{profileData?.tfa_status ? "Disable" : "Enable"}</Link>
                     </div>
                  </div>
               </div> */}
               <div className="col-xl-4">
                  <div className="card">
                     <div className="cardInnerCnt">
                        <div className="row">
                           <div className="col-lg-12">
                              <h5 className="cardLabel mb-0">Referral Link</h5>
                              <div className="d-flex align-items-center cardLinkValue truncate">
                              {/* ${window.location.origin} */}
                                 <p>{`/referral/${profileData?.memberID}/${profileData?.urlname}`}</p>
                                 {isCopied ?
                                    <CheckCircle width={20} color="#F8DC8F" className="ml-2" />
                                    :
                                    <Copy size={20} className="cardLink ml-2" style={{ cursor: "pointer" }} onClick={() => copytoClip(profileData?.memberID,profileData?.urlname)} />
                                 }
                              </div>
                              <div className="d-flex flexwrap mt-2">
                          
                              </div>
                           </div>
                           {/* <div className="col-lg-5">
                              <h5 className="cardLabel mb-0">Share Link</h5>
                              <div className="ftScl">
                                 <ul>
                                    <li data-aos="zoom-in" data-aos-duration="200">
                                       <WhatsappShareButton url={`${window.location.origin}/referral/${profileData?.ref_code}`}>
                                          <img src={whatsapp} width="18" height="18" alt="whatsapp" />
                                       </WhatsappShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="400">
                                       <FacebookShareButton url={`${window.location.origin}/referral/${profileData?.ref_code}`}>
                                          <img src={facebook} width="18" height="18" alt="facebook" />
                                       </FacebookShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="400">
                                       <TwitterShareButton url={`${window.location.origin}/referral/${profileData?.ref_code}`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-twitter">
                                             <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 
                                             4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" /></svg>
                                       </TwitterShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="800">
                                       <TelegramShareButton url={`${window.location.origin}/referral/${profileData?.ref_code}`}>
                                          <img src={telegram} width="18" height="18" alt="telegram" />
                                       </TelegramShareButton>
                                    </li>
                                 </ul>
                              </div>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-xl-4">
                  <div className="card">
                     <div className="cardInnerCnt">
                        <div className="row">
                           {/* <div className="col-lg-7">
                              <h5 className="cardLabel mb-0">Referral Link</h5>
                              <div className="d-flex align-items-center cardLinkValue truncate">
                                 <p>{`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}</p>
                                 {isCopied ?
                                    <CheckCircle width={20} color="#F8DC8F" className="ml-2" />
                                    :
                                    <Copy size={20} className="cardLink ml-2" style={{ cursor: "pointer" }} onClick={() => copytoClip(profileData?.memberID,profileData?.urlname)} />
                                 }
                              </div>
                              <div className="d-flex flexwrap mt-2">
                          
                              </div>
                           </div> */}
                           <div className="col-lg-12">
                              <h5 className="cardLabel mb-0">Share Link</h5>
                              <div className="ftScl">
                                 <ul>
                                    <li data-aos="zoom-in" data-aos-duration="200">

                                       <WhatsappShareButton url={`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}>
                                          <img src={whatsapp} width="18" height="18" alt="whatsapp" />
                                       </WhatsappShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="400">
                                       <FacebookShareButton url={`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}>
                                          <img src={facebook} width="18" height="18" alt="facebook" />
                                       </FacebookShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="400">
                                       <TwitterShareButton url={`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-twitter">
                                             <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 
                                             4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" /></svg>
                                       </TwitterShareButton>
                                    </li>
                                    <li data-aos="zoom-in" data-aos-duration="800">
                                       <TelegramShareButton url={`${window.location.origin}/referral/${profileData?.memberID}/${profileData?.urlname}`}>
                                          <img src={telegram} width="18" height="18" alt="telegram" />
                                       </TelegramShareButton>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} modalClassName="authModal">
               <ModalHeader toggle={toggle}></ModalHeader>
               <ModalBody>
                  <TwoFactor toggle={toggle} />
               </ModalBody>
            </Modal>

            <div className="row">
               <div className="col-lg-6">
                  <h3 className="cardTitle">Last Transaction</h3>
                  <TransactionHistory />
               </div>

               <div className="col-lg-6">
                  <h3 className="cardTitle">Login History</h3>
                  <LoginHistory />
               </div>
            </div>
         </div >
      </>
   )
}
export default Dashboard;